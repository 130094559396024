const Noodl = require("@noodl/noodl-sdk");
const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
const htmlToPdfmake = require("html-to-pdfmake");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function MyCustomReactComponent(props) {
	console.log("Rendering MyCustomReactComponent");
	const style = {
		color: props.textColor,
		backgroundColor: props.backgroundColor,
		borderRadius: "10px",
		padding: "20px",
		marginBottom: props.marginBottom,
	};

	const handleOnClick = async () => {
		const div = document.createElement("div");
		div.style.fontFamily = "Roboto, sans-serif"; // Ensure the font is Roboto
		div.style.position = "absolute";
		div.style.left = "-10000px";
		div.style.top = "-10000px";
		div.style.width = "595px"; // Width of an A4 sheet in pixels at 72dpi
		div.innerHTML = props.htmlContent;
		document.body.appendChild(div);

		const pdfContent = htmlToPdfmake(div.innerHTML);
		const pdfDoc = pdfMake.createPdf({ content: pdfContent });
		pdfDoc.download(props.defaultName + ".pdf");

		props.onClick();
		props.onFinished(); // Emit the onFinished signal
		document.body.removeChild(div);
	};

	return (
		<button
			className="PdfButton"
			onClick={handleOnClick}
		>
			{props.buttonText}
		</button>
	);
}

const MyCustomReactComponentNode = Noodl.defineReactNode({
	name: "htmlToPdf",
	category: "UI Elements",
	getReactComponent() {
		return MyCustomReactComponent;
	},
	inputProps: {
		backgroundColor: { type: "color", default: "white" },
		marginBottom: {
			type: { name: "number", units: ["px"], defaultUnit: "px" },
			default: 10,
		},
		htmlContent: { type: "string", default: "" },
		pdfPadding: { type: "number", default: 0 },
		defaultName: { type: "string", default: "download" },
		buttonText: { type: "string", default: "Eksporter PDF" },
	},
	outputProps: {
		onClick: { type: "signal", displayName: "Click" },
		onFinished: { type: "signal", displayName: "Finished" }, // Add the onFinished output property
	},
});

Noodl.defineModule({
	reactNodes: [MyCustomReactComponentNode],
	nodes: [],
	setup() {
		console.log("Module setup");
	},
});
